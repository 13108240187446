html {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.42857;
  font-size: 17px;
  background-color: #fff;
  color: #000;
  word-wrap: normal; }

main {
  flex: 1 0 auto; }

.big-header {
  background-attachment: scroll;
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat; }

.filter-invert {
  filter: invert(1); }

ul.unstyled-list {
  list-style: none; }

.image-fit {
  object-fit: cover;
  height: 100%; }

@media (min-width: 991px) {
  .card-columns {
    column-count: 4; } }

@media (max-width: 767px) {
  .card-columns {
    column-count: 1; } }

a.unstyled-link {
  text-decoration: inherit;
  color: inherit; }
